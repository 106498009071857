/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: snow;
  background-color: #1b202c !important;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
} */

h1 {
  font-size: 2em !important;
}

h2 {
  font-size: 1.5em !important;
}

h3 {
  font-size: 1.2em !important;
  font-weight: 700;
}
